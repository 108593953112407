import React, { useEffect } from 'react';

const ContactMe = () => {
    return (
        <>
            <div className='contactme' id='contactme'>
                <div className='container'>
                    <h2>Contact Me</h2>
                    <p className='feel'>Please feel free to contact me for your business need. I will be happy to assist you</p>
                    <div className='contactMeFooter'>
                        <p><i className='fa fa-phone'></i>+91 96635 66605</p>
                        <p className='contactMeLinks'>
                            <a title="Send an Email" href="mailto:kcwebfreelancer@gmail.com"><i className='fa fa-envelope'></i></a>
                            <a title='LinkedIn Profile' href="https://www.linkedin.com/in/karthikchandrasekaran81/" target='_blank'><i className='fa fa-linkedin-square'></i></a>
                        </p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ContactMe