import React from 'react'

const Welcome = () => {
    const handleClick = (e) => {
        window.scrollTo({
            top: document.querySelector(`#${e}`).offsetTop,
            behavior: "smooth",
        })
    };
    const handleHref = () => {
        return void(0);
    }
    return (
        <div className='highlights'>
            <div className='container'>
                <h2>Hello and Welcome to My Online Portfolio! I am a Professional Web, UI/UX, and Graphic Designer and Developer.</h2>
                <p>Please take a moment to explore my skills, domain experience, and sample works.</p>
                <a onClick={()=>handleClick('portfolio')}>Portfolio</a>
            </div>
        </div>
    )
}

export default Welcome