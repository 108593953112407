import React, { useEffect, useState } from 'react';
import BackToTop from '../components/BackToTop';
import ContactMe from '../components/ContactMe';
import Header from '../components/Header';
import Hero from '../components/Hero';
import Portfolio from '../components/Portfolio';
import Proficiency from '../components/Proficiency';
import Welcome from '../components/Welcome';
import WhatIDo from '../components/WhatIDo';

const Home = () => {
    var offset = 200;
  const [scrollPosition, setScrollPosition] = useState(0);
  const [toggle, setToggle] = useState(false);
  useEffect(() => {
    if (typeof window !== "undefined") {

      const handleScroll = () => {
        const position = window.scrollY;
        setScrollPosition(position);

        if(scrollPosition > offset){
          setToggle(true);
        }else{
          setToggle(false)
        }
      }

      window.addEventListener("scroll", handleScroll);
      return () => window.removeEventListener("scroll", handleScroll);
    }
  }, [window.scrollY])

  useEffect(()=>{
    
  }, [])
  return (
    <>
    
      <Hero />
      <Welcome />
      <Proficiency />
      <WhatIDo />
      <Portfolio />
      {toggle && <BackToTop />}
    </>
  )
}

export default Home