import React, { useState, useEffect } from 'react';

const PortfolioModel = ({ portfolioData }) => {
    let { name, type, largeImages, maxSize } = portfolioData;
    let [loading, setLoading] = useState(true);
    useEffect(() => {
        setTimeout(()=>{
            setLoading(false);
        }, 5000)
    }, [])
    return (
        <div className="modal" tabIndex="-1" id='exampleModal'>
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{name}</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        {
                            loading ? <p>Loading...</p> :
                       
                            largeImages && largeImages.map((image, index) => <img className={maxSize ? 'default' : 'small'} key={index} src={require(`../images/portfolio/large/${image}`)} />)
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PortfolioModel