import React, { useRef } from 'react'
import { Link } from 'react-router-dom';
import data from '../data/data.json';

const Navigation = () => {
    const handleClick = (e) => {
        window.scrollTo({
            top: document.querySelector(`#${e}`).offsetTop,
            behavior: "smooth",
        })
    };
    const handleHref = () => {
        return void(0);
    }
    return (
        <div className='navigation'>
            <ul>
                {
                    data.menus.map((menu, index) => {
                        let link = menu.toLowerCase();
                        link = link.replace(/\s/g, "");
                        return (
                            <li key={index} onClick={() => handleClick(link)}><a onClick={handleHref}>{menu}</a></li>
                        )
                    })
                }
            </ul>
        </div>
    )
}

export default Navigation