import React from 'react';
import PageNotFoundImage from '../images/pagenotfound.jpg'

const PageNotFound = () => {
  return (
    <div className='pagenotfound'>
      <img src={PageNotFoundImage} alt='Page Not Found' title='KC Web Freelancer'/>
    </div>
  )
}

export default PageNotFound