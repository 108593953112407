import React, { useState, useRef, useEffect } from 'react';

import class_1_mp3_file from '../digitalmarketing/tasks/01/ContentType_Formula_1_2.mp3';

const DigitalMarketing = () => {
    let [visibleItem, setVisibleItem] = useState('class1');
    let handleToggle = (name) => {
        setVisibleItem(name);
    }
    let class2Data = [
        { url: 'https://www.youtube.com/watch?v=B4e0fsPAEj0', title: 'D Cube Analytics | New Office Launch' },
        { url: 'https://www.youtube.com/watch?v=AbkdjMOGfDc&pp=ygUcY2xpZW50IHRlc3RpbW9uaWFscyBpbiB0YW1pbA%3D%3D', title: 'Testimonial Video for IT Company - NARWAL' },
        { url: 'https://www.youtube.com/watch?v=DIFCMtzg7xg', title: 'Student Testimonial - Varnika Chauhan' },
        { url: 'https://www.youtube.com/shorts/b8CchM7zY9w', title: 'Happy Customer | KG House of Champions' },
        { url: 'https://www.youtube.com/shorts/_X65oTSF5Xw', title: 'Hyderabad Property Seller Testimonial Video - Hanu Reddy Realty' },
        { url: 'https://www.youtube.com/watch?v=THpnSuuK0o8&pp=ygUvY3VzdG9tZXIgZXhwZXJpZW5jZSB3aXRoIHJlYWwgYnVpbGRlcnMgaW4gaW5kaWE%3D', title: "Customer's Experience @ Aakash Heights" },
        { url: 'https://www.youtube.com/shorts/oUlvlL8UOe8', title: 'Amolika Happy Customer Experience | Event Management' },
        { url: 'https://www.youtube.com/watch?v=apiD6JD0hso&pp=ygUlY3VzdG9tZXIgZXhwZXJpZW5jZSB3aXRoIGthbHlhbmFtYWxhaQ%3D%3D', title: "Honest Reviews: Customers Reflect on 'Wedding and Beyond'" },
        { url: 'https://www.youtube.com/shorts/QynoNh76-Ek', title: 'Foodify Startup Stories' },
        { url: 'https://www.youtube.com/watch?v=CGaP9mYGVrU', title: 'Ajay Thakur, Head – SME & Startup' },
        { url: 'https://www.youtube.com/shorts/grUecA8nHGg', title: 'Happy Moments | Kokila Priya' },
        { url: 'https://www.youtube.com/shorts/2u1BRVZhyxE', title: 'Customer Happy Experience | Krafstar Management' },
        { url: 'https://www.youtube.com/watch?v=wt0gIMG218s&pp=ygUuYnVzaW5lc3MgZW50cmVwcmVuZXVyIHN1Y2Nlc3Mgc3RvcmllcyBpbiB0YW1pbA%3D%3D', title: 'Sathish Kumar | Josh Talks' },
        { url: 'https://www.youtube.com/watch?v=g1a4MTCS0Ok', title: 'Success Story Of Nykaa' },
        { url: 'https://www.youtube.com/watch?v=HcUw0Eaz20k&pp=ygUuYnVzaW5lc3MgZW50cmVwcmVuZXVyIHN1Y2Nlc3Mgc3RvcmllcyBpbiB0YW1pbA%3D%3D', title: 'Hashini Lakshmi Narayanan' }

    ]
    return (
        <>
            <div className='digital'>
                <div className='digitalImageWrapper'>
                    <div className='digitalImageWrapperOverlay'></div>
                </div>
                <div className='program'>
                    <div className='colLeft'>
                        <h3>Training Practices</h3>
                        <ul>
                            <li onClick={() => handleToggle('class1')} className={`${visibleItem === 'class1' ? 'active' : ''}`}>Class 1</li>
                            <li onClick={() => handleToggle('class2')} className={`${visibleItem === 'class2' ? 'active' : ''}`}>Class 2</li>
                            <li onClick={() => handleToggle('class3')} className={`${visibleItem === 'class3' ? 'active' : ''}`}>Class 3</li>
                            <li onClick={() => handleToggle('class4')} className={`${visibleItem === 'class4' ? 'active' : ''}`}>Class 4</li>
                            <li onClick={() => handleToggle('class5')} className={`${visibleItem === 'class5' ? 'active' : ''}`}>Class 5</li>
                        </ul>
                    </div>
                    <div className='colRight'>
                        {
                            visibleItem === 'class1' &&
                            <div>
                                <div className='contentTitle'>
                                    <h3>Class 1</h3>
                                    <span className='date'>October 1, 2024</span>
                                </div>
                                <div className='contentBody'>
                                    <div className='contentDescription'>
                                        <p>Content Type Formula 1: Target Group, Profile & Solution, Result, CTA</p>
                                        <p>Content Type Formula 2: Result, Profile & Solution, CTA</p>
                                    </div>
                                    <audio controls type="audio/mp3" src={class_1_mp3_file}></audio>
                                </div>
                            </div>
                        }
                        {
                            visibleItem === 'class2' &&
                            <div>
                                <div className='contentTitle'>
                                    <h3>Class 2</h3>
                                    <span className='date'>October 2, 2024</span>
                                </div>
                                <div className='contentBody'>
                                    <div className='contentDescription'>
                                        <p>Content Type Formula 3: Moments, Results, Profile and CTA</p>
                                        <p>Content Type Formula 4: Why, Results, Profile and CTA</p>
                                        <p>Content Type Formula 5: Story, Profile and CTA</p>
                                    </div>
                                    <ul>
                                        <strong>Below are the few video references I have gone through from the Formula 3, 4, and 5.</strong>
                                        <br /><br />
                                        {
                                            class2Data.map((v, index) => <li key={index}>
                                                <a href={v.url} target='_blank'><i className='fa fa-youtube-play'></i>{v.title}</a>
                                            </li>)
                                        }

                                    </ul>
                                </div>
                            </div>
                        }
                        {
                            visibleItem === 'class3' &&
                            <div>
                                <p>Content will be updated soon.</p>
                            </div>
                        }
                        {
                            visibleItem === 'class4' &&
                            <div>
                                <p>Content will be updated soon.</p>
                            </div>
                        }
                        {
                            visibleItem === 'class5' &&
                            <div>
                                <p>Content will be updated soon.</p>
                            </div>
                        }



                    </div>
                </div>
            </div>
        </>
    )
}

export default DigitalMarketing