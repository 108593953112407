import React, { useRef, useState } from 'react';
import data from '../data/data.json';
import PortfolioModel from './PortfolioModel';

const Portfolio = () => {
    let [portfolioItem, setPortfolioItem] = useState({});
    let handlePortfolioItem = (item) => {
        setPortfolioItem(item);
    }
    return (
        <>
            <div className='portfolio' id='portfolio'>
                <div className='container'>
                    <h2>Portfolio</h2>
                    <p>Below are the featured client projects. Some are live, while others are currently in development.</p>
                    <ul className='portfolioItems'>
                        {
                            data.portfolio.map((p, index) => {
                                return (<li key={index}>
                                    <img src={require(`../images/portfolio/thumb/${p.thumbImage}`)} alt={p.name} />
                                    <div className='thumbInfo'>
                                        <div className='thumInfoInner'>
                                            <div className='links'>
                                                <strong>{p.name}</strong>
                                                <div className='linksWrapper'>
                                                    <a className='infoLink' onClick={() => handlePortfolioItem(p)} data-bs-toggle="modal" data-bs-target="#exampleModal"><i className='fa fa-info-circle'></i></a>
                                                    {p.link ? <a className='externalLink' title={p.link} href={p.link} target='_blank'><i className='fa fa-external-link-square'></i></a> : null}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>)
                            })
                        }
                    </ul>
                    <PortfolioModel portfolioData={portfolioItem} />
                </div>
            </div>
        </>
    )
}

export default Portfolio