import React from 'react'

const BackToTop = () => {
    let handleBackToTop = (e) => {
        e.preventDefault();
        window.scrollTo(0, 0)
    }
    return (
        <button title='Back to Top' onClick={handleBackToTop} className='goToTop'><i className='fa fa-arrow-up'></i></button>
    )
}

export default BackToTop