import React from 'react';
import data from '../data/data.json';

const Proficiency = () => {
    return (
        <>
            <div className='proficiency' id='proficiency'>
                <div className='container'>
                    <h2>Procifiency</h2>
                    <ul className='proficiencyList'>
                        {
                            data.proficiencies.map((p, index) => {
                                return (
                                    <li key={index}>
                                        <div className='barContainer'>
                                            <div className='name'>{p.name}</div>
                                            <div className='bar' style={{width: `${p.percentage - 10}%` }}></div>
                                        </div>
                                        <div className='percentage'>{p.percentage}%</div>
                                    </li>
                                )
                            })
                        }

                    </ul>
                </div>
            </div>
        </>
    )
}

export default Proficiency