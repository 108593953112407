import React, { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './css/Styles.css';

import Home from './pages/Home';
import Header from './components/Header';
import ContactMe from './components/ContactMe';
import DigitalMarketing from './pages/DigitalMarketing'
import PageNotFound from './pages/PageNotFound';


function App() {
  return (
    <div>
      <BrowserRouter>
        <Header />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/digitalmarketing" element={<DigitalMarketing />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
        <ContactMe />
      </BrowserRouter>
    </div>
  );
}

export default App;
