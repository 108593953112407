import React from 'react';
import data from '../data/data.json';

const WhatIDo = () => {
    return (
        <>
            <div className='whatido' id='whatido'>
                <div id="carouselExampleCaptions" className="carousel slide" data-bs-ride="true">
                    <div className="carousel-indicators">
                        <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                        <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
                        <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>
                    </div>
                    <div className="carousel-inner">
                        <div className="carousel-item brand active">
                            <div className='brandInner'>
                                <div className='container'>
                                    <div className="carousel-caption d-none d-md-block">
                                        <h5>Branding and Identity</h5>
                                        <ul>
                                            {

                                                data.whatido[0].items.map((item, index) => {
                                                    return (<li key={index}>{item}</li>)
                                                })
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="carousel-item web">
                            <div className='webInner'>
                                <div className='container'>
                                    <div className="carousel-caption d-none d-md-block">
                                        <h5>Web Design and Development</h5>
                                        <ul>
                                            {

                                                data.whatido[1].items.map((item, index) => {
                                                    return (<li key={index}>{item}</li>)
                                                })
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="carousel-item userinterface">
                            <div className='userinterfaceInner'>
                                <div className='container'>
                                    <div className="carousel-caption d-none d-md-block">
                                        <h5>UI/UX</h5>
                                        <ul>
                                            {

                                                data.whatido[2].items.map((item, index) => {
                                                    return (<li key={index}>{item}</li>)
                                                })
                                            }
                                        </ul>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='ucd'>
                <div className='container'>
                    <h3>User Centered Design Process (UCD)</h3>
                    <p>User-centered design (UCD) is an approach to designing products, services, or systems that prioritizes the needs, preferences, and limitations of the end users throughout the design process. The goal of UCD is to ensure that the final product is highly usable and provides a positive experience for the users.</p>
                    <ul className="ucdItems">
                        <li>
                            <div className='ucdIcon'><i className='fa fa-search'></i></div>
                            <div className='ucdTitle'>Research</div>
                        </li>
                        <li>
                            <div className='ucdIcon'><i className='fa fa-lightbulb-o'></i></div>
                            <div className='ucdTitle'>Concept</div>
                        </li>
                        <li>
                            <div className='ucdIcon'><i className='fa fa-paint-brush'></i></div>
                            <div className='ucdTitle'>Design</div>
                        </li>
                        <li>
                            <div className='ucdIcon'><i className='fa fa-cogs'></i></div>
                            <div className='ucdTitle'>Develop</div>
                        </li>
                        <li>
                            <div className='ucdIcon'><i className='fa fa-flask'></i></div>
                            <div className='ucdTitle'>Test</div>
                        </li>
                    </ul>
                </div>
            </div>
        </>
    )
}

export default WhatIDo