import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Navigation from './Navigation';
import kcLogo from '../images/kc_logo.svg';

const Header = () => {
    const [toggle, setToggle] = useState(false);
    const navigate = useNavigate();

    const [headerSize, setHeaderSize] = useState(false);
    let handleMobileMenu = () => {
        setToggle(!toggle);
    }

    let pathname = window.location.pathname;

    useEffect(() => {
        if (pathname === '/digitalmarketing') {
            setHeaderSize(true)
        } 
        else if(pathname !== '/'){
            setHeaderSize(true)
        }
        else {
            setHeaderSize(false)
        }
    }, [pathname])

    
    return (
        <>
            <div className={`header ${toggle ? 'visible' : ''} ${headerSize ? 'headerSmall' : ''}`}>
                <div className='container'>
                    <div className='logo'>
                        <Link to='/'>
                            <img src={kcLogo} alt="KC Web Freelancer" title='KC Web Freelancer' />
                        </Link>
                    </div>
                    <button className='iconSideNavigation' onClick={handleMobileMenu}><i className='fa fa-bars'></i></button>
                    {pathname === '/digitalmarketing' ? <h1 className='digitalHeading'>Digital Marketing Training Program</h1> : <Navigation />}
                </div>
            </div>
        </>
    )
}

export default Header