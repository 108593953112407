import React, { useState } from 'react';
import kcPicture from '../images/kc_pic.svg';

const Hero = () => {
    let [toggle, setToggle] = useState(false);
    let handleToggle = () => {
        setToggle(!toggle);
    }
    return (
        <>
            <div className='hero' id='hero'>
                <div className='container'>
                    <div className='heroTitle'>
                        <h1>
                            Brand, Web, UI/UX Designer and Developer
                        </h1>
                        <h2>“A good experienced designs brings insights from research as well as knowledge of human psychology—and blends them with the organisation’s product vision”</h2>
                    </div>
                    <div className={`kcPicture ${toggle ? 'visible' : ''}`}>
                        {
                            toggle ? <button className='kcInfo kcInfoClose' title="Close" onClick={handleToggle}><i className='fa fa-times-circle'></i></button> : <button className='kcInfo' onClick={handleToggle} title="About Me"
                            ><i className='fa fa-info-circle'></i></button>
                        }
                        <img src={kcPicture} alt="KC Web Freelancer"/>
                        <div className='aboutInfo'>
                            <div className='aboutInfoCircle'>
                                <p>I’m Karthik Chandrasekaran, a passionate <strong>Brand, Web, UI/UX, Designer and Developer</strong> based in Bangalore, India. With over 15+ years of expertise in web, UX/UI, and digital design, I’m here to elevate your brand. Whether you need innovative design solutions, growth strategies, or fresh business ideas, let’s collaborate to bring your vision to life!</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
           
        </>
    )
}

export default Hero